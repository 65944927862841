/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { ReactComponent as ArrowLeft } from '../Assets/arrow.svg'
import { Link,useParams } from "react-router-dom";
const NotePage = () => {
  let { id } = useParams()
  let [note, setNote] = useState(null)

  useEffect(() => {
    getNote()
}, [id])


let getNote = async () => {
    if (id === 'new') return

    let response = await fetch(`/api/notes/${id}/`)
    let data = await response.json()
    setNote(data)
}

let createNote = async () => {
    fetch(`/api/notes/`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(note)
    })
}


let updateNote = async () => {
    fetch(`/api/notes/${id}/`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(note)
    })
}


let deleteNote = async () => {
    fetch(`/api/notes/${id}/`, {
        method: 'DELETE',
        'headers': {
            'Content-Type': 'application/json'
        }
    })
    // history.push('/')
}

let handleSubmit = () => {
    console.log('NOTE:', note)
    if (id !== 'new' && note.body === '') {
        deleteNote()
    } else if (id !== 'new') {
        updateNote()
    } else if (id === 'new' && note.body !== null) {
        createNote()
    }
    // history.push('/')
}

let handleChange = (value) => {
    setNote(note => ({ ...note, 'body': value }))
    console.log('Handle Change:', note)
}

return (
  <div className='note'>
  <div className='note-header'>
    <h3>
      <Link to={'/'}>
        <ArrowLeft  onClick={handleSubmit}/>
        {id !== "new" ?(
                <button onClick={deleteNote}>Delete</button>
            ) : (
                <button onClick={handleSubmit}>Done</button>
            )}
      </Link>
    </h3>
  </div>
        <textarea onChange={(e) => { handleChange(e.target.value) }} value={note?.body}></textarea>
    </div>
)
}

export default NotePage